import * as React from 'react'
import { getVolunteerUrl, meetings } from '../meetings'
import { Card } from '../ui/cards'
import { Link } from '../ui/misc'
import { DIV } from '../ui/tags'

// const COORDINATOR =
//   'Needs a new facility coordinator to help make sure all commitments are filled and running smoothly at this location.'

// const secretary = (day, time) =>
//   `Needs a new secretary to run the meeting on the ${day} of the month at ${time}pm.`

const NEEDS_HELP = false

export const NeedHelp = props =>
  NEEDS_HELP && (
    <DIV {...props}>
      <h2>Commitments in Need of Help</h2>
      <Card>
        <p>
          While you can offer to help out at any commitment, the following ones
          especially need help:
        </p>
        <Meeting title="Hooper (Women)" meeting={meetings.women.hooper}>
          Needs another speaker for the 5th Wednesday of the month.
        </Meeting>
      </Card>
    </DIV>
  )

const Meeting = ({ title, meeting, children }) => (
  <DIV mb1lc>
    <div>
      <strong>{title}</strong>
      &nbsp; • &nbsp;
      <Link to={meeting.map} hoverUnderline>
        Location
      </Link>
      &nbsp; • &nbsp;
      <Link to={getVolunteerUrl(meeting.title)} hoverUnderline>
        Volunteer
      </Link>
    </div>
    <div>{children}</div>
  </DIV>
)
